'use client';

import { TrackingData } from '@growthbook/growthbook';
import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import Brands from 'src/constants/Brands';
import { Vertical } from 'src/constants/Verticals';
import { useAppContext } from 'src/context/app';
import { env } from 'src/env.mjs';
import { page, setAnonymousId, track } from './Analytics';
import EventNames from './EventNames';
import { ButtonAction, EventProps, Location } from './EventProps';

type TrackPageConfig = {
    disabled?: boolean;
};

export const useTrackPage = (
    name: string,
    properties?: EventProps,
    config?: TrackPageConfig,
) => {
    const params = useSearchParams();
    const anonymousId = params?.get('msh_id');
    const { previousRoute } = useAppContext();

    useEffect(() => {
        if (!config?.disabled) {
            const eventProps = {
                ...properties,
            };

            if (previousRoute) {
                eventProps.referrer = env.NEXT_PUBLIC_BASE_PATH + previousRoute;
            }

            if (anonymousId) {
                setAnonymousId(anonymousId);
            }

            page(name, '', eventProps);
        }
    }, [config?.disabled]);
};

type TrackPageProps = {
    name: string;
    eventProps?: EventProps;
};

export const TrackPage = ({ name, eventProps }: TrackPageProps) => {
    useTrackPage(name, eventProps);

    return null;
};

export function GrowthbookTracking({ data }: { data: TrackingData[] }) {
    useEffect(() => {
        data.forEach(({ experiment, result }) => {
            track(EventNames.EXPERIMENT_VIEWED, {
                experimentId: experiment.key,
                variationId: result.key,
            });
        });
    }, [data]);

    return null;
}

/* Generic click tracking event */
export const trackClick = (eventName: string, eventProps: EventProps) => {
    track(eventName, eventProps);
};

export const trackLandingPageButtonClick = (properties: EventProps) => {
    track(EventNames.LANDING_PAGE_BUTTON_CLICKED, properties);
};

export const trackTabClicked = (tab: string, vertical?: Vertical) => {
    track(EventNames.TAB_CLICKED, {
        vertical: vertical,
        tab: tab,
    });
};

export const trackNavigationItemClicked = (properties: EventProps) => {
    track(EventNames.NAVIGATION_ITEM_CLICKED, {
        location: Location.STICKY_NAV_BAR,
        button_action: ButtonAction.DIRECT,
        destination_brand: Brands.MOSHY,
        ...properties,
    });
};
