import { getAnonymousUserId } from '@get-mosh/msh-shared';
import { useEffect, useState } from 'react';
import Routes from 'src/constants/Routes';
const useMoshUrl = (
    source: string,
    medium: string,
    campaign: string,
    content: string,
    term: string,
) => {
    const [moshyUrl, setMoshyUrl] = useState<string | null>(null);
    useEffect(() => {
        const MOSH_URL = new URL(Routes.MOSH_HOME);
        MOSH_URL.searchParams.append('utm_source', source);
        MOSH_URL.searchParams.append('utm_medium', medium);
        MOSH_URL.searchParams.append('utm_campaign', campaign);
        MOSH_URL.searchParams.append('utm_content', content);
        MOSH_URL.searchParams.append('utm_term', term);
        const userId = getAnonymousUserId();
        // passes the anonymous user id to moshy for tracking
        if (userId) {
            MOSH_URL.searchParams.append('msh_id', userId);
        }
        setMoshyUrl(MOSH_URL.toString());
    }, [source, medium, campaign, content, term]);
    return moshyUrl || Routes.MOSH_HOME;
};

export default useMoshUrl;
