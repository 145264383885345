/***
* Segment naming convention - object-action framework.
* First, choose your objects (e.g., Product, Application, etc.).
* Then define actions your customers can perform on those objects (e.g., Viewed, Installed, etc.).
* Examples: Product Viewed or Application Installed.

* Proper Case for events and snake_case for properties.

* Objects: product, account, cart, order,
* Actions: clicked, viewed, added, favorited, created, updated, shared, saved, completed, cancelled, opened, deleted, removed
 */

export default {
    LANDING_PAGE_BUTTON_CLICKED: 'Landing Page Button Clicked',

    // Homepage & nav
    CATEGORY_TILE_CLICKED: 'Category Tile Clicked',
    NAVIGATION_ITEM_CLICKED: 'Navigation Item Clicked',
    TOP_BANNER_CLICKED: 'Top Banner Clicked',

    // Other components
    BUTTON_READ_MORE_REVIEWS_CLICKED: 'Button Read more reviews Clicked',
    BUTTON_PLAY_VIDEO_CLICKED: 'Button Play Video clicked',

    TAB_CLICKED: 'Tab Clicked',
    ACCORDION_EXPANDED: 'Accordion Expanded',
    MODAL_OPENED: 'Modal opened',
    BUTTON_CLICKED: 'Button Clicked',
    BUTTON_LEARN_MORE_CLICKED: 'Button Learn more Clicked',

    // Plan page
    PORTAL_BUTTON_CLICKED: 'Portal Button Clicked',

    // Checkout
    SALES_COMPLETED: 'Sales Completed',

    EXPERIMENT_VIEWED: 'Experiment Viewed',
};
