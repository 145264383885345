import { AnalyticsBrowser } from '@segment/analytics-next';
import Brands from 'src/constants/Brands';
import { env } from 'src/env.mjs';

import { EventProps } from './EventProps';

const analytics = AnalyticsBrowser.load(
    {
        writeKey: env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
        cdnURL: env.NEXT_PUBLIC_SEGMENT_CDN_URL,
    },
    {
        integrations: {
            'Segment.io': {
                apiHost: env.NEXT_PUBLIC_SEGMENT_API_HOST,
            },
        },
    },
);

export const identify = (
    userId: string,
    traits?: { [key: string]: string },
) => {
    analytics.identify(userId, {
        brand: Brands.MOSHY,
        ...traits,
    });
};

export const page = (
    name: string,
    category?: string,
    properties?: { [key: string]: string | Array<string> | null | undefined },
) => {
    analytics.page(category, name, {
        url: window.location.href,
        path: window.location.pathname,
        ...properties,
    });
};

export const track = (eventName: string, properties?: EventProps) => {
    analytics.track(eventName, {
        brand: Brands.MOSHY,
        url: window.location.href,
        path: window.location.pathname,
        ...properties,
    });
};

export const setAnonymousId = (id: string) => {
    analytics.setAnonymousId(id);
};
